<template>
    <div class="bg-color language-translation rounded-5 p-5">
        <div v-if="!loading">
            <div v-if="checkModuleAvailable" class="d-flex justify-content-between w-100">
                <div class="d-flex" style="max-width: 65vw; gap: 10px; overflow: auto">
                    <div
                        v-for="language in selectedLanguagesList"
                        :key="language.code"
                        class="my-2 d-flex align-items-center btn-save"
                        :style="{
                            'white-space': 'nowrap',
                            'background-color':
                                language.code === selectedLanguage.code ? '#5155c3 !important' : '#c3c3d1 !important',
                        }"
                    >
                        <button
                            type="button"
                            class="primary btn-save"
                            style="background: transparent !important"
                            @click="handleLanguage(language)"
                        >
                            {{ language.name }}
                        </button>
                        <ejs-tooltip
                            v-if="language.code !== 'en'"
                            class="tooltipcontainer"
                            content="Delete"
                            target="#target"
                            cssClass="customtooltip"
                        >
                            <span
                                id="target"
                                class="material-icons-outlined dark-text mx-2"
                                style="color: white !important; cursor: pointer"
                                @click="openDeleteModal(language)"
                                >close</span
                            >
                        </ejs-tooltip>
                    </div>
                </div>
                <button class="text-button no-bg" @click="showAddLanguageModal = true">
                    <img src="@/assets/px/add.png" alt="" style="height: 25px" /> Add Language
                </button>
            </div>
            <div class="my-4 mb-4 modal-list-third-party-app" style="border-radius: 10px">
                <b-table
                    v-if="checkModuleAvailable"
                    id="my-table"
                    :items="modulesData"
                    :fields="fields"
                    style="border-radius: 10px"
                    bordered
                    responsive
                >
                    <template #cell(title)="row">
                        <Dropdown
                            v-if="checkDefaultLanguage && row.item.type === 'RSS' && row.item.rss_id"
                            name="rss"
                            label=""
                            v-model="row.item.rss_id"
                            :options="rssList"
                            :disabled="true"
                        />
                        <span v-else-if="checkDefaultLanguage">
                            {{ row.item.lumecatitle ? row.item.lumecatitle : row.item.title }}
                        </span>
                        <div v-else>
                            <input
                                v-if="row.item.type === 'Lumeca'"
                                v-model="row.item.lumecatitle"
                                @input="handleModuleDataChanged"
                            />
                            <input
                                v-else-if="
                                    [
                                        'Video Link',
                                        'PDF Link',
                                        '3 Layer Module',
                                        'Web Link',
                                        'Dashboard',
                                        'Mood Tracker',
                                    ].includes(row.item.type)
                                "
                                v-model="row.item.title"
                                @input="handleModuleDataChanged"
                            />
                            <Dropdown
                                v-else-if="row.item.type === 'RSS' && row.item.rss_id"
                                name="rss"
                                label=""
                                v-model="row.item.rss_id"
                                @input="handleModuleDataChanged"
                                :options="rssList"
                                disableEmpty
                            />
                            <input
                                v-else-if="row.item.type === 'RSS'"
                                v-model="row.item.title"
                                @input="handleModuleDataChanged"
                            />
                        </div>
                    </template>
                    <template #cell(url)="row">
                        <span v-if="checkDefaultLanguage">{{ row.item.url }}</span>
                        <div v-else>
                            <input
                                v-if="row.item.isLinkEditable"
                                v-model="row.item.url"
                                @input="handleModuleDataChanged"
                            />
                        </div>
                    </template>
                    <template #cell(submoduleTitle)="row">
                        <span v-if="checkDefaultLanguage">{{ row.item.submoduleTitle }}</span>
                        <div v-else>
                            <input
                                v-if="row.item.type === 'Sub Module'"
                                v-model="row.item.submoduleTitle"
                                @input="handleModuleDataChanged"
                            />
                        </div>
                    </template>
                    <template #cell(description)="row">
                        <span v-if="checkDefaultLanguage">{{ row.item.description }}</span>
                        <div v-else>
                            <input
                                v-if="row.item.isDescriptionEditable"
                                v-model="row.item.description"
                                @input="handleModuleDataChanged"
                            />
                        </div>
                    </template>
                    <template #cell(sublinkTitle)="row">
                        <span v-if="checkDefaultLanguage">{{ row.item.sublinkTitle }}</span>
                        <div v-else>
                            <input
                                v-if="row.item.type === 'Sub Module Link'"
                                v-model="row.item.sublinkTitle"
                                @input="handleModuleDataChanged"
                            />
                        </div>
                    </template>
                    <template #cell(action)="row">
                        <div
                            v-if="
                                (row.item.type === '3 Layer Module' &&
                                    row.item.submodules &&
                                    row.item.submodules.length) ||
                                (row.item.sublinks && row.item.sublinks.length)
                            "
                            class="d-flex justify-content-center align-items-center"
                        >
                            <ejs-tooltip
                                v-if="row.item.id === expandedModuleId || row.item.id === expandedSubModuleId"
                                class="tooltipcontainer"
                                content="Collapse"
                                target="#target"
                                cssClass="customtooltip"
                            >
                                <img
                                    src="@/assets/px/unexpand.png"
                                    alt=""
                                    style="height: 25px"
                                    id="target"
                                    @click="collapseModule(row)"
                                />
                            </ejs-tooltip>
                            <ejs-tooltip
                                v-else
                                class="tooltipcontainer"
                                content="Expand"
                                target="#target"
                                cssClass="customtooltip"
                            >
                                <img
                                    src="@/assets/px/expand.png"
                                    alt=""
                                    style="height: 25px"
                                    id="target"
                                    @click="expandModule(row)"
                                />
                            </ejs-tooltip>
                        </div>
                    </template>
                </b-table>
                <div class="w-100 bg-white" v-else>
                    <div class="d-grid justify-content-center align-items-center py-5" style="min-height: 400px">
                        <h4>No modules created</h4>
                    </div>
                </div>
            </div>
            <div v-if="checkModuleAvailable" class="d-flex justify-content-end w-100 my-4">
                <button type="button" class="other-btn cancel-btn button" @click="cancel">Cancel</button>
                <button type="button" class="save-btn" @click="saveLanguages">Save</button>
            </div>
        </div>

        <AddLanguageModal
            v-if="showAddLanguageModal"
            :availableLanguagesList="selectedLanguagesList"
            @addLanguages="addLanguages"
            @close="showAddLanguageModal = false"
        />

        <SaveLanguageDataModal
            v-if="showSaveLanguageDataModal"
            @save="changeLanguage(true)"
            @close="showSaveLanguageDataModal = false"
            @cancel="changeLanguage(false)"
        />

        <DeleteLanguageModal
            v-if="showDeleteLanguageModal"
            @save="deleteLanguage"
            @close="showDeleteLanguageModal = false"
        />

        <Loading v-if="loading" />
    </div>
</template>

<script>
    import AddLanguageModal from './add_language_modal.vue';
    import SaveLanguageDataModal from './save_language_data_modal.vue';
    import DeleteLanguageModal from './delete_language_modal.vue';
    import Loading from '@/components/general/loading/loading.vue';
    import { memberProfile, clients, rssFeeds } from '@/util/apiRequests';
    import { dashboardUpdateNotification } from '@/util/helpers/graphql/activityReminders.graphql.js';

    export default {
        name: 'LanguageTranslation',
        components: { AddLanguageModal, SaveLanguageDataModal, DeleteLanguageModal, Loading },
        data() {
            return {
                loading: true,
                showAddLanguageModal: false,
                showSaveLanguageDataModal: false,
                showDeleteLanguageModal: false,
                moduleDataChanged: false,
                expandedModuleId: null,
                expandedSubModuleId: null,
                deletedLanguageData: null,
                applicationFrame: null,
                clientEmails: [],
                allLanguagesList: [],
                selectedLanguagesList: [],
                defaultLanguageModuleData: [],
                originalModuleData: [],
                modulesData: [],
                deleteLanguagesList: [],
                rssList: [],
                rssInfo: '',
                selectedLanguage: {
                    code: 'en',
                    name: 'English',
                },
                fields: [
                    { key: 'type', label: 'Module Name', class: 'table-col', thClass: 'table-col-header' },
                    { key: 'title', label: 'Module Title', class: 'table-col', thClass: 'table-col-header' },
                    { key: 'url', label: 'Link', class: 'table-col', thClass: 'table-col-header' },
                    {
                        key: 'submoduleTitle',
                        label: 'Sub Module Title',
                        class: 'table-col',
                        thClass: 'table-col-header',
                    },
                    {
                        key: 'description',
                        label: 'Description',
                        class: 'table-col',
                        thClass: 'table-col-header',
                    },
                    {
                        key: 'sublinkTitle',
                        label: 'Sub Module Link Title',
                        class: 'table-col',
                        thClass: 'table-col-header',
                    },
                    { key: 'action', label: 'Action', class: 'table-col', thClass: 'table-col-header' },
                ],
            };
        },
        computed: {
            checkModuleAvailable() {
                return this.modulesData.length && this.selectedLanguagesList.length;
            },
            checkDefaultLanguage() {
                if (!this.selectedLanguage) {
                    return false;
                }
                return this.selectedLanguage.code === 'en';
            },
        },
        methods: {
            async getAvailableLanguageList() {
                try {
                    let response = await this.$api.get(memberProfile.getAvailableLanguageList(this.$route?.query?.id));
                    if (response?.data?.success) {
                        let data = response?.data?.response;
                        let data1 = [];
                        data.forEach((language) => {
                            if (language.code === 'en') {
                                data1.unshift(language);
                            } else {
                                data1.push(language);
                            }
                        });
                        data1 = data1.filter((language) => !this.deleteLanguagesList.includes(language.code));
                        this.selectedLanguagesList = data1;
                    }
                } catch (err) {
                    this.$toasted.error('Failed to fetch Languages data');
                }
            },
            async getAllLayerModuleData() {
                try {
                    if (!this.selectedLanguagesList?.length) {
                        return;
                    }
                    let response = await this.$api.get(
                        memberProfile.getAllLayerModuleData(this.$route?.query?.id, this.selectedLanguage?.code)
                    );
                    let data = response?.data?.response?.map((item) => {
                        if (!this.applicationFrame) {
                            this.applicationFrame = item.application;
                        }
                        if (item.type === 'RSS') {
                            this.rssInfo = this.rssList.find((rss) => rss.id === item.rss_id)?.value ?? '';
                        }
                        item.isLinkEditable = item?.url?.length ? 1 : 0;
                        return item;
                    });
                    this.modulesData = data;
                    this.originalModuleData = data;
                    if (!this.defaultLanguageModuleData?.length) {
                        this.defaultLanguageModuleData = data;
                    }
                    this.expandedModuleId = null;
                    this.expandedSubModuleId = null;
                } catch (err) {
                    this.$toasted.error('Failed to fetch modules data');
                }
            },
            async addModuleData(selectedLanguagesList) {
                try {
                    let data = [...this.defaultLanguageModuleData];
                    data.map((mainmodule) => {
                        if (mainmodule.type === 'Lumeca') {
                            mainmodule.type = 'Lumeca';
                        } else if (mainmodule.type === '3 Layer Module') {
                            mainmodule.type = '3Layer';
                        } else if (mainmodule.type === 'Dashboard') {
                            mainmodule.type = 'Dashboard';
                        } else if (mainmodule.type === 'RSS') {
                            mainmodule.type = 'RSS';
                        } else {
                            mainmodule.type = '1Layer';
                        }

                        mainmodule.parent_id = mainmodule.id;
                        // delete mainmodule.id;
                        if (mainmodule.submodules) {
                            mainmodule.submodules = mainmodule?.submodules?.map((submodule) => {
                                submodule.parent_id = submodule.id;
                                // delete submodule.id;
                                if (submodule.sublinks) {
                                    submodule.sublinks = submodule?.sublinks?.map((sublink) => {
                                        sublink.parent_id = sublink.id;
                                        // delete sublink.id;
                                        return sublink;
                                    });
                                }
                                return submodule;
                            });
                        }
                        return mainmodule;
                    });
                    let response;
                    for (let i in selectedLanguagesList) {
                        const endpoint = memberProfile.addNewLanguage();
                        response = await this.$api.post(endpoint, {
                            modules: data,
                            language: selectedLanguagesList[i],
                        });
                    }
                    if (response?.data?.success) {
                        await this.sendDashboardUpdateNotification('add', this.selectedLanguage?.code);
                    }
                    this.$toasted.success(`Languages data added successfully`);
                } catch (err) {
                    this.$toasted.error('Failed to update language data');
                }
            },
            async updateModuleData(selectedLanguage) {
                try {
                    if (selectedLanguage?.code === 'en') {
                        return;
                    }
                    let data = {
                        modules: this.originalModuleData.map((mainmodule) => {
                            if (mainmodule.type === 'Lumeca') {
                                mainmodule.type = 'Lumeca';
                            } else if (mainmodule.type === '3 Layer Module') {
                                mainmodule.type = '3Layer';
                            } else if (mainmodule.type === 'Dashboard') {
                                mainmodule.type = 'Dashboard';
                            } else if (mainmodule.type === 'RSS') {
                                mainmodule.type = 'RSS';
                            } else {
                                mainmodule.type = '1Layer';
                            }
                            let moduleItem = this.modulesData.find((item1) => item1.id === mainmodule.id);
                            if (moduleItem) {
                                mainmodule.lumecatitle = moduleItem.type === 'Lumeca' ? moduleItem.lumecatitle : '';
                                mainmodule.title = moduleItem.type !== 'Lumeca' ? moduleItem.title : '';
                                mainmodule.url = moduleItem.url;
                                if (mainmodule?.submodules?.length) {
                                    mainmodule.submodules = mainmodule?.submodules?.map((submodule) => {
                                        let subModuleItem = this.modulesData.find((item1) => item1.id === submodule.id);
                                        if (subModuleItem) {
                                            submodule.title = subModuleItem.submoduleTitle;
                                            submodule.url = subModuleItem.url;
                                            submodule.description = subModuleItem.description;
                                            if (submodule?.sublinks?.length) {
                                                submodule.sublinks = submodule?.sublinks?.map((sublink) => {
                                                    let submoduleLinkItem = this.modulesData.find(
                                                        (item1) => item1.id === sublink.id
                                                    );
                                                    if (submoduleLinkItem) {
                                                        sublink.title = submoduleLinkItem.sublinkTitle;
                                                        sublink.url = submoduleLinkItem.url;
                                                    }
                                                    return sublink;
                                                });
                                            }
                                        }
                                        return submodule;
                                    });
                                }
                            }
                            return mainmodule;
                        }),
                        language: selectedLanguage,
                    };

                    const endpoint = memberProfile.updateNewLanguage();
                    const response = await this.$api.put(endpoint, data);
                    if (response?.data?.success) {
                        await this.sendDashboardUpdateNotification('update', selectedLanguage?.code);
                        this.$toasted.success(`Module data updated successfully`);
                    }
                } catch (err) {
                    this.$toasted.error('Failed to update module data');
                }
            },
            async saveLanguages() {
                this.loading = true;
                if (this.moduleDataChanged) {
                    await this.updateModuleData(this.selectedLanguage);
                }
                if (this.deleteLanguagesList?.length) {
                    const endpoint = memberProfile.deleteLanguage(this.$route?.query?.id);
                    const response = await this.$api.post(endpoint, { languages: this.deleteLanguagesList });
                    if (response?.data?.success) {
                        await this.sendDashboardUpdateNotification('delete', JSON.stringify(this.deleteLanguagesList));
                    }
                }
                this.loading = false;
                this.$router.push('/dashboard-builder');
                this.$toasted.success('Languages data saved successfully');
            },
            async changeLanguage(isLanguageModified) {
                this.loading = true;
                this.showSaveLanguageDataModal = false;
                if (isLanguageModified) {
                    await this.updateModuleData(this.selectedLanguage);
                }
                this.selectedLanguage = this.languageData;
                await this.getAllLayerModuleData();
                this.moduleDataChanged = false;
                this.loading = false;
            },
            async getDashboardRelatedClientsEmail() {
                try {
                    const endpoint = clients.getClientsEmail(this.$route.query.id);
                    const clientEmailsResponse = await this.$api.get(endpoint);

                    const {
                        data: { emails, message },
                    } = clientEmailsResponse;
                    if (emails.length != 0) {
                        this.clientEmails = emails;
                    } else {
                        throw new Error(message);
                    }

                    return true;
                } catch (err) {
                    console.error(err);
                    // this.$toasted.success(`This dashboard is not assigned to any member !`);
                }
            },
            async sendDashboardUpdateNotification(action, language) {
                try {
                    if (!this.clientEmails?.length) {
                        await this.getDashboardRelatedClientsEmail();
                    }
                    if (this.clientEmails?.length) {
                        await dashboardUpdateNotification(this.clientEmails, this.applicationFrame, action, language);
                        return true;
                    }
                } catch (err) {
                    return this.$toasted.error('Failed to send notifications to members');
                }
            },
            handleLanguage(data) {
                this.languageData = data;
                if (this.moduleDataChanged) {
                    this.showSaveLanguageDataModal = true;
                } else {
                    this.changeLanguage(false);
                }
            },
            expandModule(data) {
                if (data?.item?.type === '3 Layer Module') {
                    let subModuleList = [];
                    data.item?.submodules.forEach((subModule) => {
                        subModuleList.push({
                            id: subModule.id,
                            type: 'Sub Module',
                            submoduleTitle: subModule?.title,
                            description: subModule?.description,
                            url: subModule?.url,
                            sublinks: subModule.sublinks,
                            isLinkEditable: subModule?.url?.length ? 1 : 0,
                            isDescriptionEditable: subModule?.description?.length ? 1 : 0,
                        });
                    });
                    if (this.expandedModuleId) {
                        this.modulesData = this.modulesData.filter(
                            (item) => item.type !== 'Sub Module' && item.type !== 'Sub Module Link'
                        );
                        this.expandedModuleId = null;
                        this.expandedSubModuleId = null;
                    }
                    this.modulesData = [
                        ...this.modulesData.slice(0, data.index + 1),
                        ...subModuleList,
                        ...this.modulesData.slice(data.index + 1),
                    ];
                    this.expandedModuleId = data?.item?.id;
                } else {
                    let subModuleLinkList = [];
                    data.item?.sublinks?.forEach((subModuleLink) => {
                        subModuleLinkList.push({
                            id: subModuleLink.id,
                            type: 'Sub Module Link',
                            sublinkTitle: subModuleLink?.title,
                            url: subModuleLink.url,
                            isLinkEditable: subModuleLink?.url?.length ? 1 : 0,
                        });
                    });
                    if (this.expandedSubModuleId) {
                        this.modulesData = this.modulesData.filter((item) => item.type !== 'Sub Module Link');
                        this.expandedSubModuleId = null;
                    }
                    this.modulesData = [
                        ...this.modulesData.slice(0, data.index + 1),
                        ...subModuleLinkList,
                        ...this.modulesData.slice(data.index + 1),
                    ];
                    this.expandedSubModuleId = data?.item?.id;
                }
            },
            collapseModule(data) {
                if (data?.item?.type === '3 Layer Module') {
                    this.modulesData = this.modulesData.filter(
                        (item) => item.type !== 'Sub Module' && item.type !== 'Sub Module Link'
                    );
                    this.expandedModuleId = null;
                    this.expandedSubModuleId = null;
                } else {
                    this.modulesData = this.modulesData.filter((item) => item.type !== 'Sub Module Link');
                    this.expandedSubModuleId = null;
                }
            },
            async addLanguages(selectedLanguagesList) {
                this.showAddLanguageModal = false;
                this.loading = true;
                await this.addModuleData(selectedLanguagesList);
                await this.getAvailableLanguageList();
                await this.getAllLayerModuleData();
                this.loading = false;
                this.$toasted.success('Language added successfully');
            },
            async getRssList() {
                try {
                    let list = await this.$api.get(rssFeeds.getRssList());
                    this.rssList = list?.data?.info?.map((item) => {
                        item.value = item.id;
                        item.text = item.feedtitle;
                        return item;
                    });
                } catch (err) {
                    this.$toasted.error('Failed to fetched Rss List');
                }
            },
            openDeleteModal(data) {
                this.deletedLanguageData = data;
                this.showDeleteLanguageModal = true;
            },
            deleteLanguage() {
                if (this.deletedLanguageData) {
                    this.selectedLanguagesList = this.selectedLanguagesList.filter(
                        (language) => language.code != this.deletedLanguageData?.code
                    );
                    this.deleteLanguagesList.push(this.deletedLanguageData?.code);
                    this.showDeleteLanguageModal = false;
                    this.$toasted.success('Language deleted successfully');
                } else {
                    this.$toasted.error('Failed to delete Language deleted');
                }
                if (this.selectedLanguage?.code === this.deletedLanguageData?.code) {
                    this.selectedLanguage = this.selectedLanguagesList[0];
                }
            },
            handleModuleDataChanged() {
                this.moduleDataChanged = true;
            },
            cancel() {
                this.$router.push('/dashboard-builder');
            },
        },
        async created() {
            if (!this.$route.query.id) {
                this.$router.push('/dashboard-builder');
                this.$toasted.error('Failed to fetched Information without dashboard id');
            }
            await this.getRssList();
            await this.getAvailableLanguageList();
            await this.getAllLayerModuleData();
            this.loading = false;
        },
    };
</script>

<style scoped>
    .bg-color {
        background-color: rgba(255, 255, 255, 0.9);
    }

    .btn-save {
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
        background-color: #5155c3 !important;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 600;
        border-radius: 5px;
    }

    .text-button,
    .text-button:hover {
        background-color: #4cbc9a !important;
        color: #ffffff;
        border-radius: 10px;
        font-size: 20px;
        font-weight: 600;
        border-radius: 5px;
    }

    .other-btn {
        background: #fff;
        min-width: 150px;
        height: 50px;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
    }

    .cancel-btn {
        color: #fff;
        background: var(--button-color);
    }

    .save-btn {
        background: #5057c3;
        color: white;
        width: 150px;
        height: 50px;
        font-size: 18px;
        font-weight: 600;
        border-radius: 5px;
    }

    .text-link:hover {
        background: #5155c3;
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }
</style>

<style>
    .language-translation .table-col {
        max-width: 110px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
    }

    .language-translation .table-col-header {
        color: white !important;
        background-color: #5057c3 !important;
    }

    /* ### Custom scroll ### */

    /* width */
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--base-theme-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--base-theme-color);
    }
</style>
