<template>
    <div class="add-language-modal">
        <NoButtonModal :open="true" id="add_language_modal">
            <div v-if="!loading">
                <div class="modal-content-header">
                    <span style="font-size: 20px; font-weight: bold; margin-left: 20px">Add Language </span>
                </div>
                <div class="d-flex align-items-center flex-column w-100">
                    <div class="mt-4 mb-2 d-flex justify-content-center align-items-center w-80">
                        <input
                            class="d-flex justify-content-center align-items-center"
                            placeholder="Find Language"
                            type="text"
                            @input="searchLanguage($event.target.value)"
                        />
                    </div>
                    <div
                        class="d-flex my-2 px-2 pb-2 flex-column w-80"
                        style="gap: 10px; max-height: 40vh; height: 40vh; overflow-x: auto"
                    >
                        <div
                            v-for="language in languagesList"
                            :key="language.code"
                            class="w-95 d-flex justify-content-between language-item cursor-pointer"
                            @click="handleLanguage(language)"
                        >
                            {{ language.name }}
                            <img v-if="language.selected" src="@/assets/px/selected.png" alt="" style="height: 20px" />
                        </div>
                    </div>
                </div>
                <div class="my-2 d-flex justify-content-center" style="bottom: 10%">
                    <button
                        type="button"
                        class="primary btn-save"
                        @click="addLanguages"
                        :style="{ cursor: !selectedLanguagesList.length ? 'not-allowed' : '' }"
                        :disabled="!selectedLanguagesList.length"
                    >
                        Add Language
                    </button>
                    <button type="button" class="no-bg btn-cancel px-4" @click="$emit('close')">Cancel</button>
                </div>
            </div>

            <div
                v-if="loading"
                class="d-flex flex-column justify-content-center align-items-center loader-main"
                style="min-height: 70vh"
            >
                <b-spinner variant="primary" class="add-list-function-loader mb-1"></b-spinner>
                <span class="ms-3">Loading ...</span>
            </div>
        </NoButtonModal>
    </div>
</template>

<script>
    import NoButtonModal from '@/components/general/modals/NoButtonModal';
    import { dashboardBuilder } from '@/util/apiRequests';

    export default {
        name: 'AddLanguageModal',
        components: { NoButtonModal },
        props: {
            availableLanguagesList: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                loading: true,
                languagesList: [],
                allLanguagesList: [],
                selectedLanguagesList: [],
            };
        },
        methods: {
            async getLanguagesList() {
                try {
                    let response = await this.$api.get(dashboardBuilder.getLanguagesList());
                    this.allLanguagesList = response?.data?.response?.filter(
                        (obj1) => !this.availableLanguagesList.some((obj2) => obj2.code === obj1.code)
                    );
                    this.allLanguagesList.map((language) => {
                        language.name = language?.name?.split(',')[0];
                        return language;
                    });
                    this.languagesList = [...this.allLanguagesList];
                } catch (err) {
                    this.$toasted.error('Failed to fetch language list');
                }
            },
            async searchLanguage(value) {
                if (value === '') {
                    this.languagesList = this.allLanguagesList.map((obj1) => {
                        obj1.selected = this.selectedLanguagesList.some((obj2) => obj2.code === obj1.code);
                        return obj1;
                    });
                    return;
                }
                this.languagesList = this.allLanguagesList.filter((language) => {
                    return language.name.toLowerCase().includes(value.toLowerCase());
                });
                this.languagesList = this.languagesList.map((obj1) => {
                    return {
                        ...obj1,
                        selected: this.selectedLanguagesList.some((obj2) => obj1.code === obj2.code),
                    };
                });
            },
            handleLanguage(data) {
                if (data?.selected) {
                    this.selectedLanguagesList = this.selectedLanguagesList.filter(
                        (language) => language.code !== data.code
                    );
                } else {
                    this.selectedLanguagesList.push({
                        ...data,
                        selected: true,
                    });
                }
                this.languagesList.forEach((language) => {
                    if (language.code === data.code) {
                        language.selected = !data.selected;
                    }
                });
            },
            addLanguages() {
                this.$emit('addLanguages', this.selectedLanguagesList);
            },
        },
        async created() {
            await this.getLanguagesList();
            this.loading = false;
        },
    };
</script>

<style scoped>
    .modal-content-header {
        background-color: #f1f7ff;
        padding: 10px;
        display: flex;
        align-items: center;
    }

    .language-item {
        color: #6f757b;
        font-size: 16px;
        border-radius: 5px;
        padding: 10px 15px;
        font-weight: 600;
        box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.1);
    }

    .btn-save {
        border-radius: 10px;
        background: #5155c3 !important;
        box-shadow: 0px 10px 5px 0px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        padding: 5px;
    }

    .text-link:hover {
        background: #5155c3;
    }

    button.no-bg:hover {
        background: none;
    }

    .btn-cancel,
    .btn-cancel:hover {
        border-radius: 10px;
        border: 2px solid #ac2734;
    }

    /* ### Custom scroll ### */

    /* width */
    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #ccc3c3;
        border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: var(--base-theme-color);
        border-radius: 10px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: var(--base-theme-color);
    }
</style>

<style>
    .add-language-modal .modal__content {
        border-radius: 10px;
        padding: 0;
        height: 70vh;
        width: 30vw;
    }

    .add-language-modal input,
    .add-language-modal input:not([class='multiselect__input']),
    .add-language-modal input:focus {
        background-color: #f1f7ff !important;
        color: #6f757b !important;
        font-size: 16px !important;
        font-weight: 600 !important;
        border-radius: 10px !important;
        box-shadow: none !important;
        outline: none !important;
    }

    .add-language-modal input[type='text']::placeholder {
        color: #aab0b7 !important;
        font-size: 16px !important;
        font-weight: 600 !important;
    }
</style>
